import { memo } from 'react'; 
import { useSelector } from 'react-redux';
import { renderRows } from 'components/containers';
import { desktop } from './defaultContent';

const DesktopFooterTemplate = () => {
  const desktopContent = useSelector(state => state.footer.desktopContent);

  return renderRows(desktopContent ?? desktop);
};

export default memo(DesktopFooterTemplate);
