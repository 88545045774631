import { FEEDBACK_RECEIVED_RESULT, FEEDBACK_SUBMITTED, FeedbackAction } from './actions';

type State = {
  successStatus: boolean | null;
}

const initialState: State = {
  successStatus: null,
};

export default function reducer(state: State = initialState, action: FeedbackAction) {
  switch (action.type) {
    case FEEDBACK_RECEIVED_RESULT:
      return { ...state, successStatus: action.payload };
    case FEEDBACK_SUBMITTED:
      return { ...state, successStatus: null };
    default:
      return state;
  }
}
