import styles from './Summary.module.scss';
import { useEffect, useState, useRef, useMemo } from 'react';
import { joinClasses } from 'utils/helpers';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHeaderContext } from 'components/sections/headerContext';
import { requestBasketSummary } from 'behavior/basket';
import LoadingIndicator from './LoadingIndicator';
import Popup from './Popup';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import PreloadTexts from './PreloadTexts';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { throttle } from 'lodash';
import { RouteName } from 'routes';

const Summary = ({
  basket,
  showSubtotal,
  updatingBy,
  updatedLinesAmount,
  requestBasketSummary,
  compact,
  hideLabel,
  abilities,
  isDesignerMode,
}) => {
  const headerContext = useHeaderContext();
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { canViewPrices, popupAbilities } = useMemo(() => {
    const [viewPriceAbility, ...popupAbilities] = abilities;
    return { canViewPrices: viewPriceAbility === AbilityState.Available, popupAbilities };
  }, [abilities]);
  const [linesAmount, setLinesAmount] = useState(null);
  const timeoutId = useRef();
  const showTotals = showSubtotal && canViewPrices;
  const [basketSummary, setBasketSummary] = useState(basket);
  const throttledSetBasketSummary = useThrottle(setBasketSummary, 300);
  const throttledRequestBasketSummary = useThrottle(requestBasketSummary, 750);
  const popupHovered = useRef();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    throttledSetBasketSummary(basket);
  }, [basket]);

  useEffect(() => {
    const basketNotExpired = basketSummary && !basketSummary.expired;

    if (basketNotExpired)
      return;

    if (basketSummary && basketSummary.expired && basketSummary.loaded && !basketSummary.loading)
      throttledRequestBasketSummary(!!showTotals);
    else
      requestBasketSummary(!!showTotals);
  }, [basketSummary, showTotals]);

  useEffect(() => {
    if (isFirstRender.current && (headerContext.available && !headerContext.isSummaryOpened))
      return;

    if (!basketSummary)
      return;

    if (basketSummary && basketSummary.expired)
      return;

    const { productLines } = basketSummary;
    if (!updatedLinesAmount || !productLines || productLines.list.length === 0) {
      clearTimeout(timeoutId.current);
      setDropdownOptions({ show: false });
      return;
    }

    setLinesAmount(basketSummary.loaded && !popupHovered.current ? updatedLinesAmount : null);
    setDropdownOptions({ show: true });

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      basketSummary.loaded && setDropdownOptions({ show: false });
      setLinesAmount(null);
    }, 2000);
  }, [basketSummary && basketSummary.loaded, updatedLinesAmount]);

  useEffect(() => {
    if (basketSummary && basketSummary.loading) {
      setDropdownOptions({ show: true });
    }
  }, [basketSummary && basketSummary.loading, updatingBy]);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    if (basketSummary && basketSummary.totalCount)
      return;

    setDropdownOptions({ show: false });
  }, [basketSummary && !!basketSummary.totalCount]);

  useEffect(() => {
    headerContext.available && (headerContext.isSummaryOpened = dropdownOptions.show);
  }, [dropdownOptions]);

  useEffect(() => {
    isFirstRender.current = false;

    return () => {
      throttledSetBasketSummary.cancel();
      clearTimeout(timeoutId.current);
    };
  }, []);

  if (!basketSummary)
    return null;

  const { totalCount, loading, isAvailable } = basketSummary;

  let onHover = null;
  let onDropDownHide = null;

  if (!compact) {
    onHover = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = true;
    };
    onDropDownHide = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = false;
    };
  }

  return (
    <div
      className={joinClasses(
        'basket-summary ' + styles.summary,
        !totalCount && styles.isEmpty,
        compact && styles.compact,
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      role="presentation"
    >
      {compact
        ? (
          <>
            <Header
              totalCount={totalCount}
              hideLabel={hideLabel}
              showFixed={headerContext.available && dropdownOptions.show && !loading}
            />
            {loading && <LoadingIndicator />}
          </>
        )
        : (
          <Dropdown
            bodyAlign="right"
            options={dropdownOptions}
            className={joinClasses(
              'basket-summary ' + styles.dropdown,
              !!totalCount && styles.isEmpty,
            )}
            header={<Header totalCount={totalCount} hideLabel={hideLabel} />}
            onDropDownHide={onDropDownHide}
            hideCaretDown
            disableAnimation
          >
            {!isDesignerMode && (
              <>
                {loading && <LoadingIndicator />}
                {!loading && isAvailable && totalCount > 0 && (
                  <Popup linesAmount={linesAmount} basket={basketSummary} showTotals={showTotals} abilities={popupAbilities} />
                )}
              </>
            )}
          </Dropdown>
        )
      }
      <PreloadTexts />
    </div>
  );
};

Summary.propTypes = {
  basket: PropTypes.shape({
    totalCount: PropTypes.number,
    expired: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    isAvailable: PropTypes.bool,
  }),
  showSubtotal: PropTypes.bool,
  updatingBy: PropTypes.string,
  updatedLinesAmount: PropTypes.number,
  requestBasketSummary: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
  compact: PropTypes.bool,
  abilities: PropTypes.arrayOf(PropTypes.string),
  isDesignerMode: PropTypes.bool,
};

const canOrderAbility = [AbilityTo.OrderProducts];
//[123258][SGS] 3.2. OCI punchout
const abilityKeys = [AbilityTo.ViewPrices, AbilityTo.CreateOrder, AbilityTo.CreateQuote, AbilityTo.ViewUnitOfMeasure, AbilityTo.OCIPunschOutAbility];

const ignoredRoutes = [
  RouteName.BasketPage,
  RouteName.QuotePromotion,
];

const wrappedSummary = withAbilitiesAndRouteChecker(
  Summary,
  ignoredRoutes,
  canOrderAbility,
  abilityKeys,
);

export default connect(({
  settings: { basket },
  basket: { summary, updatingBy, updated },
}) => ({
  showSubtotal: basket && basket.showSubtotalInSummary,
  basket: summary,
  updatingBy,
  updatedLinesAmount: updated.linesAmount,
}), { requestBasketSummary })(wrappedSummary);

function useThrottle(func, wait) {
  const throttleRef = useRef();

  if (throttleRef.current == null)
    throttleRef.current = throttle(func, wait);

  return throttleRef.current;
}
