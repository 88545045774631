import { of } from 'rxjs';
import { mergeMap, first, pluck, tap } from 'rxjs/operators';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState } from 'behavior/user/constants';
import { createOfflinePage } from './helpers';
import { StoreType } from 'behavior/settings';
import { AbilityTo } from 'behavior/user/constants';

export function closedStoreMiddleware(next, routeData, state$, dependencies) {
  const filter = settings => {
    if (settings.storeType === StoreType.Closed && !isPreview(routeData))
      return authorizeMiddleware(next, routeData, state$, dependencies);

    return next(routeData, state$, dependencies);
  };

  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    mergeMap(filter),
  );
}

export function authorizeMiddleware(next, routeData, state$, dependencies) {
  const filter = user => {
    if (user.isAuthenticated)
      return next(routeData, state$, dependencies).pipe(
        tap(result => {
          if (!result || !result.page)
            return;

          result.page.authentication = {
            ...result.page.authentication,
            required: true,
          };
        }),
      );

    return of({ statusCode: 401 });
  };

  if (state$.value.user.initialized)
    return filter(state$.value.user);

  return state$.pipe(
    pluck('user'),
    first(user => user.initialized),
    mergeMap(filter),
  );
}

export const createAbilityMiddleware = ability => function (next, routeData, state$, dependencies) {
  if (isPreview(routeData))
    return next(routeData, state$, dependencies);

  return requestAbility(ability, state$, dependencies).pipe(
    mergeMap(abilityState => {
      switch (abilityState) {
        case AbilityState.NotAvailable:
          return of(null);
        case AbilityState.Unauthorized:
          return of({ statusCode: 401 });
        case AbilityState.TemporaryNotAvailable:
          return of({ statusCode: 503, page: createOfflinePage() });
        default:
          return next(routeData, state$, dependencies).pipe(
            tap(result => {
              if (!result || !result.page)
                return;

              const authentication = result.page.authentication;
              if (!authentication) {
                result.page.authentication = {
                  abilities: [ability],
                };
                return;
              }

              result.page.authentication = {
                ...authentication,
                abilities: authentication.abilities
                  ? [...authentication.abilities, ability]
                  : [ability],
              };
            }),
          );
      }
    }),
  );
};

//[123258] [SGS] 3.2. OCI punchout
export function ociPunschOutAbilityMiddleware(next, routeData, state$, dependencies) {
  if (isPreview(routeData))
    return next(routeData, state$, dependencies);

  return requestAbility(AbilityTo.OCIPunschOutAbility, state$, dependencies).pipe(
    mergeMap(abilityState => {
      switch (abilityState) {
        case AbilityState.Available:
          return of(null);
        default:
          return next(routeData, state$, dependencies);
      }
    }),
  );
};

export const applyMiddleware = (handler, ...middlewares) =>
  middlewares.reverse().reduce((next, current) => current.bind(null, next), handler);

function isPreview(routeData) {
  return routeData?.params?.previewToken != null;
}
