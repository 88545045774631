import styles from './Video.module.scss';
import { memo, useEffect, forwardRef, useRef} from 'react';
import PropTypes from 'prop-types';
import { generateKey } from 'utils/helpers';

const YOUTUBE_API_SRC = 'https://www.youtube.com/player_api';

let asyncLoadYoutubeAPI;

//158520: [SGS] Error message when loading the page
const YoutubePlayer = forwardRef(({ videoId, className = '', events, onInit, ...playerVars }, ref) => {

  //158520: [SGS] Error message when loading the page
  const playerRef = useRef();

  useEffect(() => {
    if (!playerRef.current.id)
      playerRef.current.id = `yt_player_${generateKey()}`;

    let player;
    if (!asyncLoadYoutubeAPI) {
      asyncLoadYoutubeAPI = new Promise(resolve => {
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
        const tag = document.createElement('script');
        tag.src = YOUTUBE_API_SRC;
        document.body.appendChild(tag);
      });
    }
    //158520: [SGS] Error message when loading the page
    asyncLoadYoutubeAPI.then(YT => {
      player = new YT.Player(playerRef.current.id, {
        videoId,
        playerVars: {
          origin: window.location.origin,
          rel: 0,
          showinfo: 0,
          ...playerVars,
        },
        events,
      });

      //158520: [SGS] Error message when loading the page
      onInit && onInit(player);
    });
    return () => player?.destroy();
  }, [videoId, events, playerVars]);

  //158520: [SGS] Error message when loading the page
  return (
    <div className={`${styles.frame} ${className}`} ref={ref}>
      <div ref={playerRef} />
    </div>
  );
});

YoutubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  className: PropTypes.string,
  events: PropTypes.shape({
    onReady: PropTypes.func,
    onStateChange: PropTypes.func,
    onPlaybackQualityChange: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onError: PropTypes.func,
    onApiChange: PropTypes.func,
  }),
  onInit: PropTypes.func,
};

export default memo(YoutubePlayer);