import styles from './Header.module.scss';
import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useOnPageChanged } from 'utils/hooks';
import { VerticalSliding } from 'components/primitives/transitions';
import { AccordionNav } from 'components/objects/navigation';
import { MobileLanguageSelector } from 'components/objects/languageSelector';

export const accordionId = 'mobileTopMenu';

const MobileTopMenu = ({ expanded, setExpanded }) => {
  const handleSkipLinkClick = useCallback(() => setExpanded(true), []);

  useOnPageChanged(() => setExpanded(false));

  return (
    <VerticalSliding
      id={accordionId}
      expanded={expanded}
      containerClass={styles.topMenuContent}
    >
      <MobileLanguageSelector />
      <AccordionNav isExpanded={expanded} handleSkipLinkClick={handleSkipLinkClick} />
    </VerticalSliding>
  );
};

MobileTopMenu.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileTopMenu);
