import loadable from '@loadable/component';
import { routesBuilder } from 'routes';
import createSystemRenderer from 'components/objects/systemPages/renderer';

const InvoicePayment = loadable(() => import(/*webpackChunkName:"invoice-paymnt"*/'./InvoicePayment'));

function invoicePaymentContentRenderer(page) {
  return (
    <InvoicePayment
      order={page.order}
      paymentMethods={page.paymentMethods}
      additionalCustomerData={page.additionalCustomerData}
      extraPaymentData={page.extraPaymentData}
      submitInvoiceOrderResult={page.submitInvoiceOrderResult}
    />
  );
}

export default function invoicePaymentRenderer(page) {
  const backTo = {
    route: routesBuilder.forInvoices(),
    texts: ['ButtonText_BackToInvoices', 'MyInvoices'],
  };

  return createSystemRenderer('Payment_Header', invoicePaymentContentRenderer, backTo)(page);
}
