import type { FooterState } from './types';
import type { FooterAction } from './actions';
import { FOOTER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState: FooterState = {
  desktopContent: null,
  loaded: false,
};

export default (state: FooterState = initialState, action: FooterAction): FooterState => {
  switch (action.type) {
    case FOOTER_LOADED:
      return {
        desktopContent: action.payload ? parseContent(action.payload.desktopContent) : null,
        loaded: true,
      };
    default:
      return state;
  }
};
