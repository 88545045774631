export const VISUAL_DESIGNER_UPDATE_VIEW_PAGE = 'VD/UPDATE_PAGE';
export const VISUAL_DESIGNER_UPDATE_TITLE = 'VD/UPDATE_TITLE';

export const VISUAL_DESIGNER_PREVIEW_ADD_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_ADD_ROW';
export const previewAddRow = (index, id) => ({
  type: VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_MOVE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_MOVE_ROW';
export const previewMoveRow = (indexBefore, indexAfter) => ({
  type: VISUAL_DESIGNER_PREVIEW_MOVE_ROW,
  payload: {
    indexBefore,
    indexAfter,
  },
});

export const VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DUPLICATE_ROW';
export const previewDuplicateRow = (index, id) => ({
  type: VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_DELETE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DELETE_ROW';
export const previewDeleteRow = index => ({
  type: VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  payload: { index },
});

export const VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT = 'APP/VISUAL_DESIGNER/PREVIEW_MOVE_CONTENT_ELEMENT';
export const previewMoveContentElement = (indexBefore, indexAfter, sourceElementId, targetElementId) => ({
  type: VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
  payload: {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  },
});
