import styles from './MainNav.module.scss';
import { useMemo, useState, useEffect, useRef, memo } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useLoadEffect, useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { loadNavigation } from 'behavior/navigation';
import { NavigationGroupCode } from 'behavior/navigation/constants';
import { shouldRenderNavigation } from '../helpers';
import { removeListHoverState, hoveredListClass, hoveredItemClass, disableInteractionAttr, handleAriaVisibilityState } from './eventHandlers';
import { SkipLink } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useIsTouchScreen, useHasMultiplePointers } from 'utils/detections';
import { setFocusWithoutScrolling } from 'utils/dom';
import { useIsMouse } from 'components/detection';
import { useNavMenuContext } from './hooks';

const navigationId = 'mainNavigation';
const textsToLoad = ['SkipLink_MainNavigation', 'Aria_MainNavigation', 'Aria_MainNavigation_Submenu'];

const MainNav = React.forwardRef(({
  NavItemComponent,
  navClass,
  handleSkipLinkClick,
  omitSkipLinkScroll,
  isDesignerMode,
  contentElementId,
}, ref) => {
  const { componentGroup } = useNavMenuContext();
  const { settingsLoaded, items, locationPathName } = useSelector(({
    navigation,
    settings: { loaded },
    routing: { location },
  }) => ({
    settingsLoaded: loaded,
    items: navigation[componentGroup]?.[NavigationGroupCode.Main],
    locationPathName: location?.pathname,
  }), shallowEqual);
  const [shouldRenderSkipLink, setSkipLinkRenderState] = useState(false);
  const areItemsPresent = shouldRenderNavigation(items);
  const isTouchScreen = useIsTouchScreen();
  const isMouse = useIsMouse();
  const dispatch = useDispatch();

  if (!ref)
    ref = useRef();

  const [
    skipToMainNavigationText,
    ariaMainNavigationText,
    ariaMainNavSubmenuText,
  ] = useSanaTexts(textsToLoad, stripHtmlTagsAndFormatting).texts;

  useLoadEffect(() => {
    if (settingsLoaded && !items)
      dispatch(loadNavigation(componentGroup, NavigationGroupCode.Main));
  }, [items, settingsLoaded, componentGroup]);

  useEffect(() => {
    setSkipLinkRenderState(!isDesignerMode);
  }, [isDesignerMode]);

  useResetMenuHoverStateOnPointerChange(ref, isMouse);

  const activeItemId = useMemo(() => {
    return areItemsPresent ? getActiveTopLevelItemId(items, encodeURI(locationPathName)) : null;
  }, [items, areItemsPresent, locationPathName]);

  if (!areItemsPresent)
    return null;

  return (
    <>
      {shouldRenderSkipLink && skipToMainNavigationText && ReactDOM.createPortal(
        (
          <SkipLink
            hash={`#${navigationId}`}
            title={skipToMainNavigationText}
            onClick={handleSkipLinkClick}
            omitScroll={omitSkipLinkScroll}
          />
        ),
        document.getElementById('skipLinksContainer'),
      )}
      <nav
        tabIndex="-1"
        id={navigationId}
        aria-label={ariaMainNavigationText}
        className={`${contentElementId ? `MainNav_${contentElementId} ` : ''}${navClass ? `${navClass} ` : ''}${styles.navMain}`}
        ref={ref}
      >
        {/* onMouseLeave is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position. */}
        <ul className={styles.navListRoot} onMouseLeave={isTouchScreen && isMouse === false ? null : removeListHoverState}>
          {items.map(item => (
            <NavItemComponent
              key={item.id}
              item={item}
              isActive={activeItemId === item.id}
              ariaSubmenuText={ariaMainNavSubmenuText}
              skipSubItemsRender={isDesignerMode}
            />
          ))}
        </ul>
      </nav>
    </>
  );
});

MainNav.propTypes = {
  NavItemComponent: PropTypes.object.isRequired,
  navClass: PropTypes.string,
  handleSkipLinkClick: PropTypes.func,
  omitSkipLinkScroll: PropTypes.bool,
  contentElementId: PropTypes.string,
};

export default memo(MainNav);

function getActiveTopLevelItemId(items, pathName) {
  for (const item of items) {
    if (isPathNamePresent(item, pathName)) {
      return item.id;
    }
  }
  return null;
}

/**
 * Helper to determine if provided link path is present in navigation item subtree
 * @param {object} item - navigation item object
 * @param {string} pathName - uri encoded link path
 * @returns {bool} - Returns whether item or one of its children contains provided link path
 */
function isPathNamePresent(item, pathName) {
  const { link, children } = item;

  if (link != null && encodeURI(link.url) === pathName) {
    return true;
  }

  if (children != null && !!children.length) {
    return children.some(childItem => isPathNamePresent(childItem, pathName));
  }

  return false;
}

function useResetMenuHoverStateOnPointerChange(navElementRef, isMouse) {
  const hasMultiplePointers = useHasMultiplePointers();
  const prevIsMouseRef = useRef(isMouse);
  const shouldHandleMenuHoverReset = hasMultiplePointers && !!navElementRef.current;
  const switchedFromNotMouseToMousePointer = shouldHandleMenuHoverReset && prevIsMouseRef.current === false && isMouse;

  useOnChange(() => {
    if (!switchedFromNotMouseToMousePointer)
      return;

    // If pointer changed from not mouse to mouse, temporarily disable mouse events until menu hover state will be reset
    // to avoid submenus blinking across all browsers on laptop with touch screen. Still there will be different behavior
    // in IE11 and other browsers: in IE11 submenu will unfold because of mouseover event triggered, in other browsers -
    // it will not - because this event will not be triggered for current element as mouse pointer was already over that element.
    navElementRef.current.addEventListener('mouseover', stopPropagation, true);
  }, [switchedFromNotMouseToMousePointer]);

  useEffect(() => {
    // Do not reset menu state if device does not have multiple pointers, menu has not rendered yet when it has multiple pointers,
    // pointer type has not been detected yet or there was no switch from one pointer type to another (mouse to touch/pen or vice versa).
    if (!shouldHandleMenuHoverReset || isMouse == null || prevIsMouseRef.current == null)
      return;

    // Reset menu hover state if pointer type has been changed.
    for (const hoveredItem of navElementRef.current.querySelectorAll(`.${hoveredItemClass}`)) {
      hoveredItem.classList.remove(hoveredItemClass);
      handleAriaVisibilityState(hoveredItem);
      hoveredItem.parentElement.classList.remove(hoveredListClass);
    }

    // Remove disable interaction attribute on all elements where it was present on pointer type change.
    for (const linkElement of navElementRef.current.querySelectorAll(`${disableInteractionAttr}`))
      linkElement.removeAttribute(disableInteractionAttr);

    // Trigger blur event on focused elements inside menu on pointer type change.
    if (navElementRef.current.contains(document.activeElement))
      setFocusWithoutScrolling(document.getElementById('layout'));

    // If pointer changed from non-mouse to mouse re-enable mouse events after menu hover state been reset
    // to make consistent behavior across all browsers on laptop with touch screen.
    if (switchedFromNotMouseToMousePointer)
      navElementRef.current.removeEventListener('mouseover', stopPropagation, true);
  }, [isMouse, shouldHandleMenuHoverReset, switchedFromNotMouseToMousePointer]);

  useEffect(() => void (prevIsMouseRef.current = isMouse), [isMouse]);

  useEffect(() => () => navElementRef.current?.removeEventListener('mouseover', stopPropagation, true), []);
}

function stopPropagation(e) {
  e.stopPropagation();
}
