type NewsletterInput = {
  email: string;
}

export const NEWSLETTER_SUBSCRIBE = 'NEWSLETTER/SUBSCRIBE' as const;
export const subscribeToNewsletter = (input: NewsletterInput, updatedById: string) => ({
  type: NEWSLETTER_SUBSCRIBE,
  payload: { input, updatedById },
});

export const NEWSLETTER_UNSUBSCRIBE = 'NEWSLETTER/UNSUBSCRIBE' as const;
export const unsubscribeFromNewsletter = (input: NewsletterInput, updatedById: string) => ({
  type: NEWSLETTER_UNSUBSCRIBE,
  payload: { input, updatedById },
});

export const NEWSLETTER_UPDATED = 'NEWSLETTER/UPDATED' as const;
export const updateNewsletterSubscription = (updatedById: string, email: string) => ({
  type: NEWSLETTER_UPDATED,
  payload: { updatedById, email },
});

export type NewsletterAction = ReturnType<
  | typeof subscribeToNewsletter
  | typeof unsubscribeFromNewsletter
  | typeof updateNewsletterSubscription
>
