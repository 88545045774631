import type { Preload } from './types';
import { Component, ReactNode, ErrorInfo } from 'react';
import ErrorBoundaryPage from './ErrorBoundaryPage';

type Props = {
  children: ReactNode;
}

type State = {
  hasError: boolean;
}

const errorTypeId = 'webstoreError';
type WebstoreErrorDetail = {
  type: typeof errorTypeId;
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state: State = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!window.frameElement)
      return;

    window.frameElement.dispatchEvent(new CustomEvent<WebstoreErrorDetail>('error', { detail: { type: errorTypeId, error, errorInfo } }));
  }

  render() {
    if (this.state.hasError)
      return <ErrorBoundaryPage />;

    return this.props.children;
  }
}

export default ErrorBoundary;

export const preload: Preload = {
  texts: [
    'ErrorBoundaryPage_Title',
    'ErrorBoundaryPage_ReloadMessageText',
    'ContactUs',
  ],
};
