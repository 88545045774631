import styles from './Error.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { isBrowser } from 'utils/detections';

const ErrorButton = ({ id, className = '' }) => {
  const btnStatusId = `errorBtnStatus${id}`;

  const [ariaCopy, copiedText] = useSanaTexts(['Aria_Copy', 'Copied']).texts;

  const copy = ErrorButton.canCopy && navigator.clipboard && (e => {
    navigator.clipboard.writeText(id);
    const status = document.getElementById(btnStatusId).firstElementChild;
    status.style.display = 'inline';

    setTimeout(() => status.style.display = '', 3500);
  });

  return (
    <>
      <button
        onClick={copy}
        className={styles.copy + ' ' + className}
        aria-label={ariaCopy + ': ' + id}
        aria-controls={btnStatusId}
      >
        {id}
        <FontAwesomeIcon icon={faCopy} />
      </button>
      <span role="status" id={btnStatusId}>
        <span className={styles.copied}>{copiedText}</span>
      </span>
    </>
  );
};

ErrorButton.canCopy = isBrowser && navigator.clipboard;

ErrorButton.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ErrorButton;
