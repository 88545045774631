import loadable from '@loadable/component';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouteName } from 'routes';
import { CookieBarModes } from 'behavior/settings/constants';
import { LoadingIndicator } from 'components/primitives/loadingIndicator';
import { Header, Footer, Extras, Modals } from 'components/sections';
import { HashRouter } from 'components/hash';
import LayoutShiftProvider from './LayoutShiftProvider';
import PrintControls from './PrintControls';
import { ScrollToTopButton } from 'components/primitives/buttons';
import { OfflineModeSupport } from 'behavior/app';
import { Profiler } from 'components/tools/profiler';
import { AriaStatus } from 'components/objects/ariaStatus';
import { Widget as ProductComparisonWidget } from 'components/objects/productComparison';
import { NotValidCustomerWarning } from 'components/objects/notValidCustomer';
import { AccountImpersonationBar } from 'components/objects/adminImpersonation';
import SelfPostingForm from 'components/objects/SelfPostingForm';
import { useOnChange } from 'utils/hooks';
import { setFocusWithoutScrolling } from 'utils/dom';

const LimitedAccess = loadable(() => import(/*webpackChunkName:"limited"*/'components/objects/limitedAccess'));
const OfflinePage = loadable(() => import(/*webpackChunkName:"offline"*/'components/objects/offlineMode'));
const CookieBar = loadable(() => import(/*webpackChunkName:"cookie-bar"*/'components/objects/cookieBar'));

const Layout = ({
  routeData,
  initialNavigation,
  children,
  isVisualDesigner,
  isPrintMode,
  pageLoadedTime,
  showScrollTop,
  cookieBarDisplayMode,
  limitedAccessMode,
  offline,
  appLoaded,
  emptyLayout,
  postForm,
  headerLoaded,
}) => {
  const routeName = routeData ? routeData.routeName : RouteName.NotFound;

  const layoutRef = useRef();
  const footerRef = useRef();
  const activeElRef = useRef();

  useEffect(() => {
    const className = 'mouse-click';

    const add = () => void (document.documentElement.classList.add(className));
    const remove = e => {
      if (e.key === 'Tab')
        document.documentElement.classList.remove(className);
    };

    window.addEventListener('mousedown', add);
    window.addEventListener('touchstart', add);
    window.addEventListener('keydown', remove);

    return () => {
      window.removeEventListener('mousedown', add);
      window.removeEventListener('touchstart', add);
      window.removeEventListener('keydown', remove);
    };
  }, []);

  useOnChange(() => {
    if (initialNavigation || !layoutRef.current)
      return;

    if (document.activeElement === document.body || document.activeElement === layoutRef.current)
      return;

    activeElRef.current = document.activeElement;
  }, [pageLoadedTime], false);

  useEffect(() => {
    if (!activeElRef.current)
      return;

    // Focus layout element after navigation was finished in case focus was not set manually on any child element during render.
    // Small timeout should be set for IE11 to not throw 'Incorrect function' error when calling setActive method on element in some cases.
    if (activeElRef.current === document.activeElement) {
      activeElRef.current = undefined;
      let timeoutId = setTimeout(() => {
        setFocusWithoutScrolling(layoutRef.current);
        timeoutId = null;
      });
      return () => timeoutId && clearTimeout(timeoutId);
    } else {
      activeElRef.current = undefined;
    }
  }, [pageLoadedTime]);

  if (!appLoaded)
    return null;

  if (limitedAccessMode)
    return (
      <div id="layout" tabIndex="-1">
        <div id="content" className="limited">
          <LimitedAccess />
        </div>
      </div>
    );

  if (emptyLayout)
    return (
      <div id="layout" tabIndex="-1" ref={layoutRef}>
        <div id="content" className={`minimal page-${routeName}`}>
          {children}
          <Extras />
        </div>
      </div>
    );

  if (offline)
    return (
      <div id="layout" tabIndex="-1">
        <div id="content" className="offline">
          <OfflinePage />
        </div>
      </div>
    );

  const topBlockContent = !isVisualDesigner && (
    <>
      <AccountImpersonationBar />
      <NotValidCustomerWarning />
      {!isPrintMode && cookieBarDisplayMode === CookieBarModes.Top && <CookieBar />}
    </>
  );

  const bottomBlockContent = !isVisualDesigner
    && !isPrintMode
    && cookieBarDisplayMode === CookieBarModes.Bottom
    && <CookieBar />;

  return (
    <div id="layout" tabIndex="-1" ref={layoutRef}>
      <LayoutShiftProvider
        topShiftElRef={layoutRef}
        bottomShiftElRef={footerRef}
        topBlockContent={topBlockContent}
        bottomBlockContent={bottomBlockContent}
      >
        <HashRouter>
          <div id="skipLinksContainer" />
          {!isVisualDesigner && (
            <>
              {routeData && <LoadingIndicator />}
              {headerLoaded && <Header isPrintMode={isPrintMode} />}
            </>
          )}
          {isPrintMode && <PrintControls />}
          <div role="main" id="content" className={`page-${routeName}`}>
            {children}
          </div>
          {!isVisualDesigner
            ? (
              <>
                <Footer ref={footerRef} />
                <Extras>
                  <AriaStatus />
                  <ProductComparisonWidget />
                  {postForm && <SelfPostingForm formOptions={postForm} />}
                </Extras>
                <Profiler />
                {showScrollTop && <ScrollToTopButton isPrintMode={isPrintMode} />}
                <Modals />
              </>
            )
            : <Extras />
          }
        </HashRouter>
      </LayoutShiftProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  routeData: PropTypes.shape({
    routeName: PropTypes.string.isRequired,
  }),
  initialNavigation: PropTypes.bool,
  isVisualDesigner: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  postForm: PropTypes.object,
  pageLoadedTime: PropTypes.number,
  showScrollTop: PropTypes.bool,
  cookieBarDisplayMode: PropTypes.string,
  limitedAccessMode: PropTypes.bool,
  offline: PropTypes.bool,
  appLoaded: PropTypes.bool,
  emptyLayout: PropTypes.bool,
  headerLoaded: PropTypes.bool,
};

export default connect(mapStateToProps)(Layout);

function mapStateToProps({
  routing: { routeData, previous },
  visualDesigner,
  page: { isPrintMode, postForm, loadedTime: pageLoadedTime },
  settings,
  app,
  localization,
  page,
  header,
}) {
  const showScrollTop = settings.navigation && settings.navigation.scrollTop;
  const cookieBarDisplayMode = settings.cookiebar && settings.cookiebar.mode;
  const { offlineMode, offlineModeSupport, loaded, error, limitedAccessMode } = app;
  const { currentLanguage } = localization;

  return {
    routeData,
    initialNavigation: !previous,
    isVisualDesigner: visualDesigner.initialized,
    isPrintMode,
    postForm,
    pageLoadedTime,
    showScrollTop,
    cookieBarDisplayMode,
    limitedAccessMode,
    offline: offlineMode && offlineModeSupport === OfflineModeSupport.Disabled,
    appLoaded: error || loaded && (!!currentLanguage.id || currentLanguage.id === undefined), // undefined will be set as language ID in case there was some error.
    emptyLayout: page && page.emptyLayout,
    headerLoaded: header.loaded,
  };
}
