import { ofType, combineEpics } from 'redux-observable';
import { map, pluck, flatMap } from 'rxjs/operators';
import {
  INSITE_EDITOR_SANA_TEXT_DELETE_COMPLETED,
  INSITE_EDITOR_SANA_TEXT_REVERT_COMPLETED,
  sanaTextChanged,
} from './actions';
import { reloadLocation } from 'behavior/routing';
import { updateConstructedText } from './actions';

function completeSanaTextDelete(action$, state$) {
  return action$.pipe(
    ofType(INSITE_EDITOR_SANA_TEXT_DELETE_COMPLETED),
    pluck('payload'),
    flatMap(({ sanaText }) => {
      const { key, textToShowAfterDelete } = sanaText;
      const constructedTexts = state$.value.insiteEditor.constructed;
      const isConstructedText = constructedTexts?.find(text => text.key === key);

      const result = [sanaTextChanged({ key, text: textToShowAfterDelete })];

      if (isConstructedText && textToShowAfterDelete === null)
        result.push(reloadLocation(), updateConstructedText({ key, deleted: true }));

      return result;
    }),
  );
}

function completeSanaTextRevert(action$) {
  return action$.pipe(
    ofType(INSITE_EDITOR_SANA_TEXT_REVERT_COMPLETED),
    pluck('payload', 'sanaText'),
    map(sanaTextChanged),
  );
}

export default combineEpics(
  completeSanaTextDelete,
  completeSanaTextRevert,
);
