import { NAVIGATION_LOADED, MAIN_NAVIGATION_CHILDREN_LOADED } from './actions';
import { createReducer } from 'utils/redux';
import { updateTreeItem } from './helpers.js';
import { NavigationGroupCode } from './constants';

const initialState = {};

export default createReducer(initialState, {
  [NAVIGATION_LOADED]: onNavigationLoaded,
  [MAIN_NAVIGATION_CHILDREN_LOADED]: onMainNavigationChildrenLoaded,
});

function onNavigationLoaded(state, action) {
  const { componentGroup, groupCode, items } = action.payload;

  return {
    ...state,
    [componentGroup]: {
      ...state[componentGroup],
      [groupCode]: items,
    },
  };
}

function onMainNavigationChildrenLoaded(state, action) {
  const { componentGroup, parentId, children: newChildren } = action.payload;
  const rootItems = state[componentGroup]?.[NavigationGroupCode.Main];

  if (!rootItems)
    return state;

  const newRootItems = updateTreeItem(rootItems,
    item => item.id === parentId,
    item => item.children = newChildren);

  if (rootItems === newRootItems)
    return state;

  return {
    ...state,
    [componentGroup]: {
      ...state[componentGroup],
      [NavigationGroupCode.Main]: newRootItems,
    },
  };
}
