import {
  VISUAL_DESIGNER_LOADED,
  VISUAL_DESIGNER_SET_PAGE_ERRORS,
  VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED,
  VISUAL_DESIGNER_SET_SETTINGS,
} from './actions';
import { createReducer } from 'utils/redux';

const initialState = {
  initialized: false,
  pageErrors: {},
  resourceTexts: {},
  settings: {},
};

export default createReducer(initialState, {
  [VISUAL_DESIGNER_LOADED]: onLoaded,
  [VISUAL_DESIGNER_SET_PAGE_ERRORS]: onSetPageErrors,
  [VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED]: onReceiveResourceTexts,
  [VISUAL_DESIGNER_SET_SETTINGS]: onSetSettings,
});

function onLoaded(state, action) {
  return {
    ...state,
    initialized: true,
    mode: action.payload.mode,
  };
}

function onSetPageErrors(state, action) {
  const { pageErrors } = action.payload;
  return {
    ...state,
    pageErrors,
  };
}

function onReceiveResourceTexts(state, action) {
  const { texts } = action.payload;
  return {
    ...state,
    resourceTexts: {
      ...state.resourceTexts,
      ...texts,
    },
  };
}

function onSetSettings(state, action) {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}
