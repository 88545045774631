exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OpenerButton_btn-menu-opener{width:64px;height:62px;padding:0;font-size:24px;background-color:transparent;color:currentColor;cursor:pointer;border-color:var(--header_BorderColor,#e6e6e6);border-style:solid;border-width:0 1px}.OpenerButton_btn-menu-opener.active{background-color:var(--header_Menu_Sticky_BackgroundColor,#fff);color:grey}.OpenerButton_icon{max-width:.3em;max-height:.25em}", ""]);

// exports
exports.locals = {
	"btn-menu-opener": "OpenerButton_btn-menu-opener",
	"btnMenuOpener": "OpenerButton_btn-menu-opener",
	"icon": "OpenerButton_icon"
};