import type { Handler } from '../types';
import { loadContentPageQuery } from './queries';
import { of } from 'rxjs';
import { pluck, map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { parseContent, RowContentElement, RowContentElementData } from 'behavior/content';
import { RouteName } from 'routes';

type ContentPageData = {
  metaTitle: string;
  metaDescription: string | null;
  header: string | null;
  content: RowContentElementData[];
};

type LoadContentPageResponse = {
  pages: {
    content: ContentPageData;
  };
};

type ContentPageRouteData = {
  routeName: RouteName.ContentPage;
  params?: { id: string };
}

type ContentPage = {
  metaTitle: string;
  metaDescription: string | null;
  header: string | null;
  content: RowContentElement[];
  layoutHeroEffect?: {
    headerOnTop: boolean;
    headerTextColor: string | null;
    altSmallLogo: string | null;
    altLogo: string | null;
  };
  component: PageComponentNames.Content;
}

const handler: Handler<ContentPageRouteData, ContentPage> = ({ params }, _state$, { api }) => {
  if (!params?.id)
    return of(null);

  return api.graphApi<LoadContentPageResponse>(loadContentPageQuery, { id: params.id }).pipe(
    pluck('pages', 'content'),
    map(page => {
      if (!page)
        return null;

      const result = { page: page as ContentPage };
      result.page.component = PageComponentNames.Content;
      const content = parseContent(page.content);
      result.page.content = content;

      const firstRowHeroSettings = content[0]?.heroEffect;

      if (firstRowHeroSettings) {
        const { headerOnTop, headerTextColor, altSmallLogo, altLogo } = firstRowHeroSettings;
        result.page.layoutHeroEffect = { headerOnTop, headerTextColor, altSmallLogo, altLogo };
      }

      return result;
    }),
  );
};

export default handler;
