import type { HeaderState } from './types';
import type { HeaderAction } from './actions';
import { HEADER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState: HeaderState = {
  desktopContent: null,
  loaded: false,
};

export default (state: HeaderState = initialState, action: HeaderAction): HeaderState => {
  switch (action.type) {
    case HEADER_LOADED:
      return {
        desktopContent: action.payload ? parseContent(action.payload.desktopContent) : null,
        loaded: true,
      };
    default:
      return state;
  }
};
