import styles from './StyleWrapper.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ContentHorizontalAlignment } from './constants';
import { NoBorderStyleValue } from '../containers/constants';
import { joinClasses, getMinValue } from 'utils/helpers';

const StyleWrapper = ({
  children,
  border,
  spacing,
  minHeight,
  minWidth,
  horizontalAlignment,
  contentOrientation,
  stretchHeight,
  stretchWidth,
}) => {
  const { xs, sm, md } = useResponsiveBreakpoints();
  const hasOverflow = useMemo(() => border.radius && border.radius.split(/\s+/).every(val => val !== '0px'), [border.radius]);
  const actualMinHeight = useMemo(() => getMinValue(minHeight, xs, sm, md), [xs, sm, md, minHeight]);
  const actualMinWidth = useMemo(() => getMinValue(minWidth, xs, sm, md), [xs, sm, md, minWidth]);

  return (
    <div
      className={joinClasses(
        styles.wrapper,
        stretchHeight && (contentOrientation === 'VERTICAL' ? styles.flexStretchHeight : styles.stretchHeight),
        stretchWidth && (contentOrientation === 'HORIZONTAL' ? styles.flexStretchWidth : styles.stretchWidth),
        hasOverflow && styles.overflow,
      )}
      style={{
        borderWidth: border.style !== NoBorderStyleValue ? (border.width || 0) : null,
        borderStyle: border.style,
        borderColor: border.color,
        borderRadius: border.radius,
        padding: spacing.padding || null,
        margin: spacing.margin || null,
        minHeight: actualMinHeight,
        minWidth: actualMinWidth,
        textAlign: ContentHorizontalAlignment[horizontalAlignment] || null,
      }}
    >{children}</div>
  );
};

const PropTypesMinValue = PropTypes.shape({
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
}).isRequired;

StyleWrapper.propTypes = {
  children: PropTypes.any,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypesMinValue,
  minWidth: PropTypesMinValue,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  contentOrientation: PropTypes.string,
  stretchHeight: PropTypes.bool,
  stretchWidth: PropTypes.bool,
};

export default StyleWrapper;
