import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import DesktopNavBase from './DesktopNavBase';
import NavMenuContext from '../NavMenuContext';
import { Helmet } from 'react-helmet';

const CascadingMainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({ componentGroup: ComponentGroup.CascadingMainMenu }), []);

  return (
    <>
      <Helmet>
        <style>{`
          .MainNav_${id} .${styles.hypTopLvl} {
            font-family: ${model.topLevel_FontFamily || '"Pathway Gothic One"'}, sans-serif;
            font-size: ${model.topLevel_FontSize || '20px'};
            text-transform: ${model.topLevel_AllCaps ? 'uppercase' : 'none'};
            color: ${model.topLevel_FontColor || '#000'};
          }

          html.pointer-mouse .MainNav_${id} .${styles.hypTopLvl}:hover {
            color: ${model.topLevel_HoverFontColor || '#000'};
          }

          @media not all and (min-resolution:.001dpcm) {
            @media (pointer: coarse) {
              .MainNav_${id} .${styles.hypTopLvl}:hover {
                -webkit-tap-highlight-color: ${model.topLevel_HoverFontColor || '#000'};
              }
            }
          }

          .MainNav_${id} .${styles.navListRoot}.nav-is-hovered .${styles.hypTopLvl} {
            color: ${model.topLevel_NonHoveredItemFontColor || 'grey'};
          }

          ${model.topLevel_ShowActiveItemUnderline ? `
            .MainNav_${id} .${styles.navItemRoot}.${styles.active} .${styles.hypTopLvl} > span:after {
              content: '';
              background-color: ${model.topLevel_ActiveItemUnderlineColor || '#cb2245'};
            }
          ` : ''}

          .MainNav_${id} .${styles.navItemRoot}.hover .${styles.hypTopLvl},
          .MainNav_${id} .${styles.subItems} {
            background-color: ${model.topLevel_ExpandedMenuBackgroundColor || '#fff'};
            border-color: ${model.topLevel_ExpandedMenuBorderColor || '#e6e6e6'};
          }

          .MainNav_${id} .${styles.hypNestedLvl} {
            font-family: ${model.subLevel_FontFamily || '"Open Sans"'}, sans-serif;
            font-size: ${model.subLevel_FontSize || '13px'};
            text-transform: ${model.subLevel_AllCaps ? 'uppercase' : 'none'};
            color: ${model.subLevel_FontColor || '#333'};
            padding-left: 0;
          }

          html.pointer-mouse .MainNav_${id} .${styles.hypNestedLvl}:hover {
            color: ${model.subLevel_HoverFontColor || '#333'};
          }

          .MainNav_${id} .${styles.navItemSub}.hover {
            background-color: ${model.subLevel_HoverBackgroundColor || '#f7f7f7'};
          }

          ${model.subLevel_ShowArrowInFrontOfSubItem ? `
            .MainNav_${id} .${styles.hypNestedLvl} {
              padding-left: 0.55em;
            }

            .MainNav_${id} .${styles.hypNestedLvl}:before {
              content: '› ';
            }
          ` : ''}
        `}</style>
      </Helmet>
      <NavMenuContext.Provider value={context}>
        <DesktopNavBase isDesignerMode={isDesignerMode} contentElementId={id} />
      </NavMenuContext.Provider>
    </>
  );
};

CascadingMainMenuBlock.propTypes = {
  model: PropTypes.shape({
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_HoverFontColor: PropTypes.string,
    topLevel_NonHoveredItemFontColor: PropTypes.string,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ExpandedMenuBackgroundColor: PropTypes.string,
    topLevel_ExpandedMenuBorderColor: PropTypes.string,
    subLevel_FontFamily: PropTypes.string,
    subLevel_FontSize: PropTypes.string,
    subLevel_AllCaps: PropTypes.bool.isRequired,
    subLevel_FontColor: PropTypes.string,
    subLevel_HoverFontColor: PropTypes.string,
    subLevel_HoverBackgroundColor: PropTypes.string,
    subLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default CascadingMainMenuBlock;
