import { forwardRef, memo } from 'react';
import DesktopFooterTemplate from './footer/DesktopFooterTemplate';

const Footer = forwardRef((_, ref) => (
  <footer id="footer" ref={ref}>
    <DesktopFooterTemplate />
  </footer>
));

export default memo(Footer);
