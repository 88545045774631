type FeedbackInput = {
  companyName?: string;
  name: string;
  email: string;
  phoneNumber?: string;
  comments: string;
  captchaToken?: string;
}

export const FEEDBACK_SUBMITTED = 'FEEDBACK/FORM/SUBMITTED' as const;
export const sendFeedback = (data: FeedbackInput) => ({ type: FEEDBACK_SUBMITTED, payload: data });

export const FEEDBACK_RECEIVED_RESULT = 'FEEDBACK/RESPONSE/RECEIVED' as const;
export const feedbackReceived = (success: boolean) => ({
  type: FEEDBACK_RECEIVED_RESULT,
  payload: success,
});

export type FeedbackAction = ReturnType<
  | typeof sendFeedback
  | typeof feedbackReceived
>
