import styles from '../AccountMenu.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'behavior/user';

//[123258][SGS] 3.2. OCI punchout
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

//[123258][SGS] 3.2. OCI punchout

const LogoutButton = ({ logoutDelay = 0 }) => {
  const dispatch = useDispatch();
  const timeoutIdRef = useRef(null);
  const ociUrl = useSelector(state => state.user.ociUrl);

  const handleClick = () => timeoutIdRef.current = setTimeout(() => dispatch(logout()), logoutDelay);
  const handlePunchoutClick = () => {
    timeoutIdRef.current = setTimeout(() => dispatch(logout()), logoutDelay);
    window.location.href = ociUrl;
  }
  //[123258][SGS] 3.2. OCI punchout
  const OCIPunschOutAbility = useHasAbilities(AbilityTo.OCIPunschOutAbility)[0];
  //[123258][SGS] 3.2. OCI punchout
  useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

  return (
    <>
      {!OCIPunschOutAbility &&
        <div className={styles.bodyFooter}>
          <button className={`${linkStyles.link} ${linkStyles.arrowed}`} onClick={handleClick}>
            <SimpleText textKey="Logout_LinkText" />
          </button>
        </div>
      }
      {OCIPunschOutAbility &&
        <div className={styles.bodyFooterOci}>
        <button className={`${linkStyles.link} ${linkStyles.arrowed}`} onClick={handlePunchoutClick}>
            <SimpleText textKey="OciPunchout_Logout_LinkText" />
          </button>
        </div>
      }
    </>
  );
};

LogoutButton.propTypes = {
  logoutDelay: PropTypes.number,
};

export default LogoutButton;
