export default Object.freeze([
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 9,
          'md': 9,
          'sm': 9,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'border': {
              'color': null,
              'radius': null,
              'style': 'none',
              'width': null,
            },
            'horizontalAlignment': 'LEFT',
            'id': '6iqm9p38q',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'navigationGroupCode': 'SITEMAP',
              'headingLinks_ThemeFontSize': 'Large',
              'headingLinks_AllCaps': false,
              'headingLinks_FontColor': '#4c4c4c',
              'headingLinks_HoverFontColor': '#333',
              'links_ThemeFontSize': 'Regular',
              'links_AllCaps': false,
              'links_FontColor': '#4c4c4c',
              'links_HoverFontColor': '#333',
              'links_ShowArrowInFront': false,
            },
            'name': 'LinkMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'LEFT',
        'id': 'e3doozupi',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': '0px 0px 20px',
        'verticalAlignment': 'INHERITED',
      },
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 3,
          'md': 3,
          'sm': 3,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'border': {
              'color': null,
              'radius': null,
              'style': 'none',
              'width': null,
            },
            'horizontalAlignment': 'LEFT',
            'id': 'hcve8x11i',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'showTitle': true,
              'showDescription': true,
            },
            'name': 'NewsletterSubscription',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'w7e3ixddk',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': '0px 0px 20px',
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': false,
    'heroEffect': null,
    'id': '3p3094fp1',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': false,
      'margin': '0px',
      'padding': '28px 0px 0px',
    },
    'verticalAlignment': 'TOP',
  },
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 6,
          'md': 6,
          'sm': 6,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'border': {
              'color': null,
              'radius': null,
              'style': 'none',
              'width': null,
            },
            'horizontalAlignment': 'CENTER',
            'id': 'i9m1hapkv',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'CopyrightText',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': '3piflvqp7',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': '10px 0px',
        'verticalAlignment': 'INHERITED',
      },
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 6,
          'md': 6,
          'sm': 6,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'border': {
              'color': null,
              'radius': null,
              'style': 'none',
              'width': null,
            },
            'horizontalAlignment': 'CENTER',
            'id': '6tcd7uu49',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'navigationGroupCode': 'FOOTER',
              'headingLinks_ThemeFontSize': 'Regular',
              'headingLinks_AllCaps': false,
              'headingLinks_HoverFontColor': '#333',
              'links_ThemeFontSize': 'Regular',
              'links_AllCaps': false,
              'links_HoverFontColor': '#333',
              'links_ShowArrowInFront': false,
            },
            'name': 'LinkMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'RIGHT',
        'id': 'y6honj0qb',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': '10px 0px',
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': false,
    'heroEffect': null,
    'id': 'dy1y6asgm',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': false,
      'margin': '0px',
      'padding': '10px 0px',
    },
    'verticalAlignment': 'TOP',
  },
]);
