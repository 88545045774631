import { ThemeFontSize } from 'behavior/theme';

export function getThemeFontSizeClassName(themeFontSize: ThemeFontSize) {
  switch (themeFontSize) {
    case ThemeFontSize.Large:
      return 'font-size-large';
    case ThemeFontSize.Small:
      return 'font-size-small';
    case ThemeFontSize.Micro:
      return 'font-size-micro';
    case ThemeFontSize.Heading1:
      return 'font-size-h1';
    case ThemeFontSize.Heading2:
      return 'font-size-h2';
    case ThemeFontSize.Heading3:
      return 'font-size-h3';
    case ThemeFontSize.Heading4:
      return 'font-size-h4';
    case ThemeFontSize.Heading5:
      return 'font-size-h5';
    case ThemeFontSize.DisplayHeading1:
      return 'font-size-dh1';
    case ThemeFontSize.DisplayHeading2:
      return 'font-size-dh2';
    case ThemeFontSize.DisplayHeading3:
      return 'font-size-dh3';
    default:
      return 'font-size-regular';
  }
}
