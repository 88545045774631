import type { Epic } from 'behavior/types';
import type { CustomerCollectionData } from './types';
import { CUSTOMERS_REQUESTED, customersLoaded, RepresentationAction } from './actions';
import { exhaustMap, filter, map, takeUntil, observeOn, share } from 'rxjs/operators';
import { getCustomersToRepresent } from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { asyncScheduler } from 'rxjs';
import { ofType } from 'redux-observable';
import { skipIfPreview } from 'behavior/preview';

type CustomersToRepresentResponse = {
  profile: {
    impersonation: {
      customers: CustomerCollectionData;
    };
  };
};

const representationEpic: Epic<RepresentationAction> = (action$, state$, { api, logger }) => {
  const requests$ = action$.pipe(ofType(CUSTOMERS_REQUESTED), share());
  return requests$.pipe(
    observeOn(asyncScheduler),
    skipIfPreview(state$),
    exhaustMap(action => {
      const keywords = action.payload.keywords;
      const keywordsChanged$ = requests$.pipe(
        filter(a => a.payload.keywords !== keywords),
      );
      return api.graphApi<CustomersToRepresentResponse>(getCustomersToRepresent, action.payload).pipe(
        filter(r => r.profile.impersonation != null),
        map(({ profile }) => customersLoaded(profile.impersonation.customers, keywords)),
        retryWithToast(action$, logger),
        takeUntil(keywordsChanged$),
      );
    }),
  );
};

export default representationEpic;
