import { createProductPageQuery } from './queries';
import { tap, first, switchMap, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { initComponent } from 'behavior/pages/helpers';
import { parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { routesBuilder } from 'routes';
import { Presets } from './constants';
import { PageComponentNames } from '../componentNames';
import { printModeEnabled } from 'behavior/printMode';
import { filterExistingImages } from 'utils/helpers';

export default ({ params: { id, agreementLine: salesAgreementLineId } }, state$, { api, scope }) => id
  ? state$.pipe(
    first(state => state.settings.loaded),
    switchMap(state => {
      const options = {
        relatedProducts: {
          includeLargeImages: scope === 'SERVER',
        },
        scope,
        isInsiteEditor: state.insiteEditor.initialized,
      };

      const query = createProductPageQuery(options);
      const variables = {
        productId: id,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        detailsPreset: Presets.Details,
        loadRelatedProductsCategories: state.analytics && state.analytics.isTrackingEnabled,
      };

      const isPrintMode = printModeEnabled(state.routing);

      return api.graphApi(query, variables).pipe(
        pluck('pages', 'product'),
        initComponent(PageComponentNames.Product),
        tap(result => parsePageContent(result, isPrintMode)),
        tap(result => {
          const page = result && result.page;
          if (page && !!salesAgreementLineId)
            page.salesAgreement = { preSelectedLine: { id: salesAgreementLineId } };
        }),
        tap(filterImages),
        tap(result => copyProductFromState(result, state)),
      );
    }),
  )
  : of(null);

function parsePageContent(result, isPrintMode) {
  const page = result && result.page;
  if (!page)
    return;

  if (page.middleContent)
    page.middleContent = parseContent(page.middleContent);

  if (page.footerContent)
    page.footerContent = parseContent(page.footerContent);

  parseRelatedProducts(page.product);

  // Prevent page indexing by robots in case sorting is present in URL.
  page.index = !isPrintMode;
  page.isPrintMode = isPrintMode;
}

function parseRelatedProducts(product) {
  if (!product || !product.relatedProductGroups)
    return;

  for (const group of product.relatedProductGroups)
    for (const relatedProduct of group.products)
      relatedProduct.routeData = routesBuilder.forProduct(relatedProduct.id);
}

function copyProductFromState(result, state) {
  const page = result && result.page;
  const product = state.page.product;

  if (
    state.routing.navigatingTo.location !== state.routing.location
    && page
    && product
    && product.id === page.product.id
  ) {
    page.product = {
      ...product,
      ...page.product,
      loaded: false,
    };
  }
}

export function filterImages(result) {
  const page = result && result.page;
  if (page) {
    page.product.images = filterExistingImages(page.product.images);
  }
}
