import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { useSelector } from 'react-redux';

const CopyrightTextBlock = () => {
    const shopName = useSelector(state => state.settings.shopName);
  
    return (
      <span className="copyright">
        {/* Сlass 'copyright' is used in web tests. */}
        <SimpleText textKey="Footer_Copyright" formatWith={[new Date().getFullYear(), shopName]} />
      </span>
    );
  };

export default memo(CopyrightTextBlock);
