import loadable from '@loadable/component';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import createSystemRenderer from 'components/objects/systemPages/renderer';
//[123258][SGS] 3.2. OCI punchout
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
//[123258][SGS] 3.2. OCI punchout

const Checkout = loadable(() => import(/*webpackChunkName:"checkout"*/'./Checkout'));
const OrderSubmit = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderSubmit'));
const OrderFailed = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderFailed'));
const OrderCancelled = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderCancelled'));
const PaymentError = loadable(() => import(/*webpackChunkName:"checkout"*/'./paymentError'));

/* eslint-disable react/no-multi-comp */
function checkoutContentRenderer(page) {
  return <Checkout info={page.info} />;
}

export function checkoutRenderer(page) {
  //[123258][SGS] 3.2. OCI punchout
  const OCIPunschOutAbility = useHasAbilities(AbilityTo.OCIPunschOutAbility)[0];
  //[123258][SGS] 3.2. OCI punchout
  let titleTextKey = page.info.quote
    ? 'AcceptQuote'
    : page.info.isQuote
      ? 'Checkout_RequestQuote'
      : 'Checkout_SecureCheckout';
  //[123258][SGS] 3.2. OCI punchout
  titleTextKey = OCIPunschOutAbility ? 'Checkout_OCIPunchout' : titleTextKey;
  //[123258][SGS] 3.2. OCI punchout
  const backTo = page.info.quote
    ? {
      texts: ['BackToQuote'],
      url: page.info.quote.url,
      route: routesBuilder.forDocument(page.info.quote.id, DocumentType.Quote),
    }
    : null;

  return createSystemRenderer(titleTextKey, checkoutContentRenderer, backTo)(page);
}

function orderSubmitContentRenderer(page) {
  return <OrderSubmit transaction={page.transaction} />;
}

export function orderSubmitRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Quote || checkoutProcessName === CheckoutProcessNames.EditQuote
    ? 'SubmitQuote_QuoteProcessedSuccessfully'
    : checkoutProcessName === CheckoutProcessNames.Invoice
      ? 'SubmitInvoice_ProcessedSuccessfully'
      : 'SubmitOrder_OrderProcessedSuccessfully';

  return createSystemRenderer(headerTextKey, orderSubmitContentRenderer)(page);
}

function orderFailedContentRenderer(page) {
  return <OrderFailed checkoutProcessName={page.transaction.checkoutProcessName} />;
}

export function orderFailedRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoicePaymentFailed_Header'
    : 'OrderFailed_Header';

  return createSystemRenderer(headerTextKey, orderFailedContentRenderer)(page);
}

function orderCancelledContentRenderer(page) {
  return (
    <OrderCancelled
      checkoutProcessName={page.transaction.checkoutProcessName}
      document={page.transaction.document}
      transactionId={page.transaction.id}
    />
  );
}

export function orderCancelledRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoiceCancelled_Header'
    : checkoutProcessName === CheckoutProcessNames.Promotion
      ? 'QuotePaymentCancelled_Header'
      : 'OrderCancelled_Header';

  return createSystemRenderer(headerTextKey, orderCancelledContentRenderer)(page);
}

function paymentErrorPageRenderer(page) {
  return (
    <PaymentError
      transactionId={page.transaction.id}
      document={page.transaction.document}
      checkoutProcessName={page.transaction.checkoutProcessName}
    />
  );
}

export function paymentErrorRenderer(page) {
  return createSystemRenderer('OrderPaymentError_Header', paymentErrorPageRenderer)(page);
}
