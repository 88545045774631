import type { RegistrationResult, TemplateFields } from './types';

export const REGISTRATION_PROCESSED = 'REGISTRATION_PROCESSED' as const;
export const registrationProcessed = (result: RegistrationResult) => ({
  type: REGISTRATION_PROCESSED,
  payload: result,
});

export const REGISTRATION_TEMPLATEFIELDS_RECEIVED = 'REGISTRATION_TEMPLATEFIELDS_RECEIVED' as const;
export const templateFieldsReceived = (fields: TemplateFields | null) => ({
  type: REGISTRATION_TEMPLATEFIELDS_RECEIVED,
  payload: fields,
});

export type RegistrationAction = ReturnType<
  | typeof registrationProcessed
  | typeof templateFieldsReceived
>