export const insiteEditorReady = () => ({
  type: 'INSITE/WEBSTORE/READY',
});

export const INSITE_EDITOR_SANA_TEXTS_REQUESTED = 'INSITE/SANA_TEXTS_REQUESTED';
export const sanaTextRequested = textKeys => ({
  type: INSITE_EDITOR_SANA_TEXTS_REQUESTED,
  payload: { textKeys },
});

export const INSITE_EDITOR_SET_TEXT_ONLY_MODE = 'INSITE/SET_TEXT_ONLY_MODE';
export const setTextOnlyMode = textKeys => ({
  type: INSITE_EDITOR_SET_TEXT_ONLY_MODE,
  payload: { textKeys },
});

export const INSITE_EDITOR_SAVE_SANA_TEXT = 'INSITE/SAVE_SANA_TEXT';
export const saveSanaText = (languageId, { key, text }) => ({
  type: INSITE_EDITOR_SAVE_SANA_TEXT,
  payload: { languageId, sanaText: { key, text } },
});

export const INSITE_EDITOR_REVERT_SANA_TEXT = 'INSITE/REVERT_SANA_TEXT';
export const revertSanaText = (languageId, key) => ({
  type: INSITE_EDITOR_REVERT_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_DELETE_SANA_TEXT = 'INSITE/DELETE_SANA_TEXT';
export const deleteSanaText = (languageId, key) => ({
  type: INSITE_EDITOR_DELETE_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_EDIT_SANA_TEXT = 'INSITE/EDIT_SANA_TEXT';
export const editSanaText = (languageId, key, isCustom, isEmptyText, isTextOnlyMode) => ({
  type: INSITE_EDITOR_EDIT_SANA_TEXT,
  payload: { key, languageId, isCustom, isEmptyText, isTextOnlyMode },
});

export const INSITE_EDITOR_SANA_TEXTS_LOADED = 'INSITE/SANA_TEXTS_LOADED';
export const sanaTextsLoaded = (languageId, textKeys) => ({
  type: INSITE_EDITOR_SANA_TEXTS_LOADED,
  payload: { languageId, textKeys },
});

export const INSITE_EDITOR_LANGUAGE_CHANGED = 'INSITE/LANGUAGE_CHANGED';
export const languageChanged = languageId => ({
  type: INSITE_EDITOR_LANGUAGE_CHANGED,
  payload: { languageId },
});

export const INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS = 'INSITE/ADD_CONSTRUCTED_TEXTS';
export const addConstructedTexts = texts => ({
  type: INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS,
  payload: { texts },
});

export const INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT = 'INSITE/UPDATE_CONSTRUCTED_TEXT';
export const updateConstructedText = ({ key, deleted }) => ({
  type: INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT,
  payload: { text: { key, deleted } },
});

export const INSITE_EDITOR_SET_SANA_TEXTS_INFO = 'INSITE/SET_SANA_TEXTS_INFO';
export const INSITE_EDITOR_SANA_TEXT_REVERT_COMPLETED = 'INSITE/SANA_TEXT_REVERT_COMPLETED';
export const INSITE_EDITOR_SANA_TEXT_DELETE_COMPLETED = 'INSITE/SANA_TEXT_DELETE_COMPLETED';

export const INSITE_EDITOR_SANA_TEXT_CHANGED = 'INSITE/SANA_TEXT_CHANGED';
export const sanaTextChanged = ({ key, text }) => ({
  type: INSITE_EDITOR_SANA_TEXT_CHANGED,
  payload: { key, text },
});

export const INSITE_EDITOR_LOADED = 'INSITE/LOADED';
export const insiteEditorLoaded = () => ({
  type: INSITE_EDITOR_LOADED,
});

export const INSITE_EDITOR_RESOURCE_TEXTS_RECEIVED = 'INSITE/RESOURCE_TEXTS_RECEIVED';
export const INSITE_EDITOR_SET_SETTINGS = 'INSITE/SET_SETTINGS';
