exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Error_action-list,.Error_body p{margin:0}.Error_body .Error_action-btn{color:var(--toasts_ErrorFontColor,#600502);text-decoration:underline;font-weight:700}html.pointer-mouse .Error_body .Error_action-btn:hover{color:var(--toasts_ErrorFontColor,#600502)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Error_body .Error_action-btn:active{color:var(--toasts_ErrorFontColor,#600502)}}}.Error_body .Error_copy{-webkit-text-decoration:underline dotted;text-decoration:underline dotted;color:inherit;font-weight:700;-webkit-user-select:text;-ms-user-select:text;user-select:text}html.pointer-mouse .Error_body .Error_copy:hover{color:inherit}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Error_body .Error_copy:active{color:inherit}}}.Error_copy svg{font-size:.9em;height:1em;margin-left:.5em}.Error_copied{padding-left:.8em;-webkit-text-decoration:underline dotted;text-decoration:underline dotted;-webkit-user-select:none;-ms-user-select:none;user-select:none;display:none}.Error_block ul{padding-left:1.5em}.Error_block .Error_copy{-webkit-text-decoration:underline dotted;text-decoration:underline dotted;-webkit-user-select:text;-ms-user-select:text;user-select:text}.Error_block .Error_loading,.Error_body .Error_loading{margin:.5em 0;box-shadow:0 0 1px 0 rgba(0,0,0,.05)}.Error_block .Error_loading{display:inline-block;width:100%;vertical-align:bottom}h1 .Error_loading{width:50%;height:.8em}.Error_err-boundary{margin:auto 0;padding:30px 0;text-align:center}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Error_err-boundary{min-height:1px}}.Error_err-boundary h1 .Error_loading,.Error_logo{display:inline-block}.Error_logo{margin-bottom:1em;max-width:100%}.Error_logo a{pointer-events:none}", ""]);

// exports
exports.locals = {
	"action-list": "Error_action-list",
	"actionList": "Error_action-list",
	"body": "Error_body",
	"action-btn": "Error_action-btn",
	"actionBtn": "Error_action-btn",
	"copy": "Error_copy",
	"copied": "Error_copied",
	"block": "Error_block",
	"loading": "Error_loading",
	"err-boundary": "Error_err-boundary",
	"errBoundary": "Error_err-boundary",
	"logo": "Error_logo"
};